import gql from "graphql-tag"
import { useMutation, useApolloClient } from "@apollo/react-hooks"
import { useHistory } from "react-router"
import { getToken } from "../../../services/firebase/push-notification"
import { AUTH_TOKEN } from "../../../constants"
import { setUser } from "../../../services/identifyUser"

const DeleteDeviceMutation = gql`
  mutation($token: String!) {
    deleteDevice(token: $token)
  }
`

export const useLogout = () => {
  const [deleteDevice] = useMutation(DeleteDeviceMutation)
  const { push } = useHistory()
  const client = useApolloClient()
  const logout = async () => {
    try {
      const token = await getToken()
      if (token) {
        await deleteDevice({
          variables: {
            token,
          },
        })
      }
    } catch (e) {
      console.error("error removing token")
    }
    localStorage.removeItem(AUTH_TOKEN)
    setUser()
    if (client) client.clearStore()
    const query = gql`
      query {
        viewerInfo {
          id
        }
      }
    `
    client.writeQuery({
      query,
      data: {
        viewerInfo: null,
      },
    })
    push("/login")
  }
  return logout
}
