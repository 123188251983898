import { tick } from "./actions.js"

let vp = null
let state = null
let ticker = null
let playPromise = null
let elem = null
const videoMiddleware = store => next => action => {
  switch (action.type) {
    case "SET_INITIAL_CONDITIONS":
      clearInterval(ticker)
      next(action)
      break
    case "PAUSE":
      state = store.getState()
      vp = state.video.videoPlayer
      clearInterval(ticker)
      if (vp === null || vp === undefined) {
        break
      }
      vp.pause()
      next(action)
      break
    case "SET_FULLSCREEN":
      state = store.getState()
      vp = state.video.videoPlayer
      elem = vp.instance.node
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen()
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen()
      }
      next(action)
      break
    case "TOGGLE_PLAY_PAUSE":
      state = store.getState()
      vp = state.video.videoPlayer
      if (vp === null || vp === undefined) {
        break
      }
      if (!playPromise) {
        playPromise = state.video.playing
          ? vp.instance.node.pause()
          : vp.instance.node.play()
      } else {
        playPromise.then(() => {
          playPromise = state.video.playing
            ? vp.instance.node.pause()
            : vp.instance.node.play()
        })
      }
      if (!state.video.playing) {
        ticker = setInterval(() => {
          store.dispatch(tick())
        }, 50)
      } else {
        clearInterval(ticker)
      }
      next(action)
      break
    case "TICK":
      state = store.getState()
      vp = state.video.videoPlayer
      vp ? next(action) : clearInterval(ticker)
      break
    case "SET_FEEDBACK_LESSON_TIME":
      state = store.getState()
      vp = state.video.videoPlayer
      if (!vp) {
        next(action)
        break
      }
      if (state.feedback.timeRanges.length > 0) {
        const index = state.feedback.timeRanges.findIndex(
          ({ exerciseSectionId }) =>
            exerciseSectionId === action.payload.exerciseSectionId,
        )
        if (index >= 0) {
          if (
            state.feedback.timeRanges[index].beginning !==
            action.payload.beginning
          ) {
            vp.setCurrentTime(action.payload.beginning)
          } else {
            //else if (state.feedback.timeRange.ending !== action.payload.ending) {
            vp.setCurrentTime(action.payload.ending)
          }
        }
      } else {
        if (action.payload.beginning !== 0) {
          vp.setCurrentTime(action.payload.beginning)
        } else {
          //else if (action.payload.ending !== vp.duration()) {
          vp.setCurrentTime(action.payload.ending)
        }
      }
      next(action)
      break
    case "RESTART_VIDEO":
      state = store.getState()
      vp = state.video.videoPlayer
      if (state.feedback.timeRange && state.feedback.timeRange.beginning) {
        vp.setCurrentTime(state.feedback.timeRange.beginning)
      } else {
        vp.setCurrentTime(0)
      }
      next(action)
      break
    case "FORWARD_VIDEO":
      vp = store.getState().video.videoPlayer
      vp.setCurrentTime(vp.getCurrentTime() + 5)
      next(action)
      break
    case "REWIND_VIDEO":
      vp = store.getState().video.videoPlayer
      vp.setCurrentTime(vp.getCurrentTime() - 5)
      next(action)
      break
    case "DESTROY_VIDEO":
      clearInterval(ticker)
      next(action)
      break
    case "SET_VIDEO_TIME":
      vp = store.getState().video.videoPlayer
      vp.setCurrentTime(action.payload.time)
      next(action)
      break
    default:
      next(action)
  }
  next(tick())
}

export default videoMiddleware
