import firebase from "firebase/app"
import "firebase/messaging"
export const getToken = async () => {
  const messaging = firebase.messaging()
  try {
    await messaging.requestPermission()
    const token = await messaging.getToken()
    return token
  } catch (e) {
    console.error("error obtaining token")
    return null
  }
}

export default () => {
  firebase.initializeApp({
    apiKey: "AIzaSyDyYF0gzu3QgSl-Vk41kldLYzUB9UKLBWo",
    messagingSenderId: "144055457653",
    projectId: "lapp-ab7a3",
    appId: "1:144055457653:web:9488549aef6ce58a58653c",
  })

  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then(registration => {
      firebase.messaging().useServiceWorker(registration)
    })
}
