import React, { useState } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import LinearProgress from "@material-ui/core/LinearProgress"

const ProgressBar = ({
  seek,
  current,
  duration,
  classes,
  setThumbnailTime,
  markers = [],
}) => {
  const [draging, setDraging] = useState(false)
  const getPositionFromEvent = e => {
    return (
      ((e.clientX - e.currentTarget.getBoundingClientRect().left) * duration) /
      e.currentTarget.offsetWidth
    )
  }
  const handleSeek = e => {
    seek(getPositionFromEvent(e))
  }
  const enableDraging = () => {
    setDraging(true)
    document.addEventListener("mouseup", disableDraging)
  }
  const disableDraging = () => {
    setDraging(false)
    document.removeEventListener("mouseup", disableDraging)
  }
  if (duration === 0) return null
  return (
    <div
      className={classes.progressContainer}
      onMouseMove={e => setThumbnailTime(getPositionFromEvent(e).toFixed(2))}
      onMouseLeave={() => setThumbnailTime(null)}
    >
      <LinearProgress
        value={(current * 100) / duration}
        variant="determinate"
        onClick={handleSeek}
        onMouseMove={e => {
          if (draging) handleSeek(e)
        }}
        onMouseDown={enableDraging}
        onMouseUp={disableDraging}
        color="secondary"
        className={classes.progressBar}
        classes={{ bar: classes.noTransitionBar }}
      />
      {markers.map(({ time, id }) => {
        return (
          <div
            className={classes.marker}
            key={id}
            style={{
              left: `calc(${(time * 100) / duration}% - 5px)`,
            }}
            onClick={() => seek(time)}
          />
        )
      })}
      <div
        className={classes.progressHead}
        style={{
          left: `calc(${(current * 100) / duration}% - 10px)`,
        }}
      />
    </div>
  )
}

const style = theme => ({
  progressContainer: {
    position: "relative",
    flexGrow: 1,
  },
  progressBar: {
    width: "100%",
    height: 10,
  },
  noTransitionBar: {
    transition: "none",
  },
  progressHead: {
    width: 20,
    height: 20,
    background: theme.palette.secondary.dark,
    borderRadius: "100%",
    position: "absolute",
    top: "calc(50% - 10px)",
    pointerEvents: "none",
    zIndex: 1001,
  },
  marker: {
    width: 10,
    height: 10,
    background: theme.palette.primary.dark,
    borderRadius: "100%",
    position: "absolute",
    top: "calc(50% - 5px)",
    cursor: "pointer",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
})
export default withStyles(style)(ProgressBar)
