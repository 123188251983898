import React from "react"
import { CenterOverlay } from "../Overlay"
import withStyles from "@material-ui/core/styles/withStyles"
import CircularProgress from "@material-ui/core/CircularProgress"

const PlayOverlay = ({ loading }) =>
  loading && (
    <CenterOverlay>
      <CircularProgress color="secondary" />
    </CenterOverlay>
  )

const styles = {
  playIcon: {},
}

export default withStyles(styles)(PlayOverlay)
