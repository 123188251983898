import React, { useState } from "react"
//import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from "@material-ui/core/MenuItem"
//import FormControl from '@material-ui/core/FormControl';
import Select from "@material-ui/core/Select"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
  hidden: {
    visibility: "hidden",
    maxWidth: 0,
    minHeight: 0,
  },
}))

const ControlledOpenSelect = ({ language }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation("common")

  const [actualLanguage, setLanguage] = useState(language ? language : "es")
  const [open, setOpen] = useState(false)

  const handleChange = event => {
    setLanguage(event.target.value)
    i18n.changeLanguage(event.target.value)
  }

  return (
    <div className={classes.menuButton} autoComplete="off">
      <Button
        className={classes.menuButton}
        color="inherit"
        onClick={() => setOpen(true)}
      >
        {t("nav.language")}
      </Button>

      <Select
        className={classes.hidden}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        value={actualLanguage}
        onChange={handleChange}
        inputProps={{
          name: "language",
          id: "controlled-open-select",
        }}
      >
        <MenuItem value={"es"}>{t("nav.es")}</MenuItem>
        <MenuItem value={"en"}>{t("nav.en")}</MenuItem>
        <MenuItem value={"pt"}>{t("nav.pt")}</MenuItem>
      </Select>
    </div>
  )
}

export default ControlledOpenSelect
