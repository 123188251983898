import React, { Suspense, lazy } from "react"

import { Helmet } from "react-helmet"
import { Switch, Route } from "react-router"
import { verifyAuthHOC } from "../../components/security/auth"
import Splash from "../../components/splash"
import { makeStyles } from "@material-ui/core"

const Login = lazy(() => import("./../Login"))
const Verification = lazy(() => import("../../screens/Verification"))
const Recovery = lazy(() => import("./../../screens/RecoverPassword"))
const Reset = lazy(() => import("./../../screens/ResetPassword"))
const Users = lazy(() => import("./../Users"))
const UserView = lazy(() => import("./../Users/View"))
const Tutorials = lazy(() => import("./../Tutorials"))
const Home = lazy(() => import("./../Home"))
const Profile = lazy(() => import("./../Profile"))
const ProfileEditForm = lazy(() => import("./../Profile/EditForm"))
const CommonErrors = lazy(() => import("./../CommonErrors"))
//const UploadLecture = lazy(() => import('./../UploadLecture';))
const EvaluationsList = lazy(() => import("./../../screens/EvaluationsList"))
const TrainEvaluationList = lazy(() => import("../TrainEvaluations/components/FeedbacksList"))
const FeedbackNew = lazy(() => import("../Feedback"))
const TrainEvaluationNew = lazy(() => import("../TrainEvaluation"))
const Assistence = lazy(() => import("./../Assistence/"))
const Establishments = lazy(() => import("./../Establishments/"))
const FavoriteFeedback = lazy(() => import("./../../screens/FavoriteFeedback/"))
const Activity = lazy(() => import("../../screens/Activity"))
const NewUserForm = lazy(() => import("./../Users/Form/CreateUser"))
const EditUserForm = lazy(() => import("./../Users/Form/EditUser"))

const EstablishmentForm = lazy(() =>
  import("./../Establishments/EstablishmentForm"),
)
const NotFound = lazy(() => import("../../components/Errors/NotFound"))
const Dashboard = lazy(() => import("./../Dashboard"))
const TheoricMaterial = lazy(() => import("./../TheoricMaterial"))

const Surveys = lazy(() => import("../Surveys"))
const SurveyView = lazy(() => import("../Surveys/components/SurveyView"))
const NewSurveyView = lazy(() => import("../Surveys/NewSurvey"))
const EditSurveyView = lazy(() => import("../Surveys/EditSurvey"))
const SurveyResultsView = lazy(() => import("../Surveys/SurveyResults"))

const ExamsList = lazy(() => import("../Questionnaires/Exams/List"))
const ExamView = lazy(() => import("../Questionnaires/Exams/View"))
const NewExamView = lazy(() => import("../Questionnaires/Exams/New"))
const EditExamView = lazy(() => import("../Questionnaires/Exams/Edit"))

const Redeem = lazy(() => import("../Redeem"))
const UploadFeedback = lazy(() => import("../Upload/Feedback"))

const UserLevelCRUD = lazy(() => import("../../screens/UserLevelCRUD"))
const AwardsCRUD = lazy(() => import("../../screens/AwardsCRUD"))

const needUnauth = verifyAuthHOC({ needUnauth: true, needAuth: false })
const needAuth = verifyAuthHOC()
const needSuperadmin = verifyAuthHOC({
  requiredRoles: ["superadmin"],
})
const needAdmin = verifyAuthHOC({
  requiredRoles: ["superadmin", "admin", "teacher"],
})
const needTeacher = verifyAuthHOC({
  requiredRoles: ["superadmin", "admin", "teacher"],
})
const requireAdmin = verifyAuthHOC({
  requiredRoles: ["admin"],
})

const requireTeacher = verifyAuthHOC({
  requiredRoles: ["teacher"],
})

const withBodyBackground = color => Component => {
  const BackgroundColored = props => {
    return (
      <>
        <Helmet>
          <style>
            {`
            body {
              background: ${color};
            }
            `}
          </style>
        </Helmet>
        <Component {...props} />
      </>
    )
  }
  return BackgroundColored
}
const blue =
  "linear-gradient(270deg, rgba(0,98,158,1) 48%, rgba(0,128,222,1) 100%)"

const useStyle = makeStyles(theme => ({
  container: {
    padding: theme.spacing(5),
  },
}))
const App = () => {
  const classes = useStyle()
  return (
    <div className={classes.container}>
      <Suspense fallback={<Splash />}>
        <Switch>
          <Route
            exact
            path="/login"
            component={needUnauth(withBodyBackground(blue)(Login))}
          />
          <Route exact path="/verificacion" component={Verification} />
          <Route
            exact
            path="/recuperar"
            component={withBodyBackground(blue)(Recovery)}
          />
          <Route
            exact
            path="/reiniciar/:token"
            component={withBodyBackground(blue)(Reset)}
          />

          <Route exact path="/" component={needAuth(Home)} />

          <Route
            exact
            path="/usuarios/historial/:id"
            component={needTeacher(UserView)}
          />

          <Route exact path="/usuarios" component={needAdmin(Users)} />
          <Route
            exact
            path="/usuarios/nuevo"
            component={needAdmin(NewUserForm)}
          />
          <Route
            exact
            path="/usuarios/nuevo/estudiante"
            component={needAdmin(() => (
              <NewUserForm roleId={4} />
            ))}
          />
          <Route
            exact
            path="/usuarios/nuevo/profesor"
            component={needSuperadmin(() => (
              <NewUserForm roleId={3} />
            ))}
          />
          <Route
            exact
            path="/usuarios/nuevo/admin"
            component={needSuperadmin(() => (
              <NewUserForm roleId={2} />
            ))}
          />

          <Route
            exact
            path="/usuarios/editar/:id"
            component={needAdmin(EditUserForm)}
          />
          <Route
            exact
            path="/centros"
            component={needSuperadmin(Establishments)}
          />
          <Route
            exact
            path="/centros/nuevo"
            component={needSuperadmin(EstablishmentForm)}
          />
          <Route
            exact
            path="/centros/editar"
            component={needSuperadmin(EstablishmentForm)}
          />
          <Route
            exact
            path="/perfil/editar"
            component={needTeacher(ProfileEditForm)}
          />
          <Route
            exact
            path="/evaluar/:id"
            component={needTeacher(FeedbackNew)}
          />
          <Route
            exact
            path="/evaluar-ttt/:id"
            component={needTeacher(TrainEvaluationNew)}
          />
          <Route
            exact
            path="/evaluaciones"
            component={needTeacher(EvaluationsList)}
          />
          <Route
            exact
            path="/evaluaciones-ttt"
            component={needTeacher(TrainEvaluationList)}
          />
          <Route exact path="/historial" component={needTeacher(Activity)} />
          <Route
            exact
            path="/evaluacion/:id"
            component={needTeacher(FeedbackNew)}
          />
          <Route
            exact
            path="/evaluacion-ttt/:id"
            component={needTeacher(TrainEvaluationNew)}
          />
          <Route exact path="/tutoriales" component={needAuth(Tutorials)} />
          <Route exact path="/perfil" component={needAuth(Profile)} />
          <Route
            exact
            path="/errores-comunes"
            component={needAuth(CommonErrors)}
          />
          <Route
            exact
            path="/material-teorico"
            component={needTeacher(TheoricMaterial)}
          />
          <Route exact path="/encuestas" component={needSuperadmin(Surveys)} />
          <Route
            exact
            path="/encuestas/:id"
            component={needSuperadmin(SurveyView)}
          />
          <Route
            exact
            path="/encuestas/:id/editar"
            component={needSuperadmin(EditSurveyView)}
          />
          <Route
            exact
            path="/encuestas/:id/resultados"
            component={needSuperadmin(SurveyResultsView)}
          />
          <Route
            exact
            path="/encuestas-nuevo"
            component={needSuperadmin(NewSurveyView)}
          />
          <Route exact path="/examenes" component={needSuperadmin(ExamsList)} />
          <Route
            exact
            path="/examenes/nuevo"
            component={needSuperadmin(NewExamView)}
          />
          <Route
            exact
            path="/examenes/:id(\d+)"
            component={needSuperadmin(ExamView)}
          />
          <Route
            exact
            path="/examenes/:id/editar"
            component={needSuperadmin(EditExamView)}
          />
          <Route exact path="/dashboard" component={needAdmin(Dashboard)} />
          <Route
            exact
            path="/upload/feedback"
            component={needAdmin(UploadFeedback)}
          />
          <Route
            exact
            path="/asistencias"
            component={requireAdmin(Assistence)}
          />
          <Route exact path="/redeem" component={requireAdmin(Redeem)} />

          <Route
            exact
            path="/feedbacks-favoritos"
            component={requireTeacher(FavoriteFeedback)}
          />

          <Route
            exact
            path="/niveles-de-usuario"
            component={needSuperadmin(UserLevelCRUD)}
          />
          <Route exact path="/logros" component={needSuperadmin(AwardsCRUD)} />

          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </div>
  )
}

export { App }

export default App
