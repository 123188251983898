import React from "react"

import lappLogo from "../assets/images/lapp_logo_white.png"
import { makeStyles } from "@material-ui/core/styles"

const useStyle = makeStyles({
  fullContainer: {
    width: "100vw",
    height: "100vh",
    background:
      "linear-gradient(270deg, rgba(0,98,158,1) 48%, rgba(0,128,222,1) 100%)",
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  image: {
    width: "50vw",
    maxWidth: 300,
  },
})
const Splash = () => {
  const classes = useStyle()
  return (
    <div className={classes.fullContainer}>
      <img className={classes.image} src={lappLogo} alt="Lapp Logo" />
    </div>
  )
}

export default Splash
