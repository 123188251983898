import React from "react"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import amber from "@material-ui/core/colors/amber"
import blue from "@material-ui/core/colors/blue"

/* Theme / Styling */
const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: amber,
  },
})

const Provider = ({ children }) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
)
export default Provider
