const OPEN_MENU = "OPEN_MENU"
const CLOSE_MENU = "CLOSE_MENU"
const TOGGLE_MENU = "TOGGLE_MENU"

export default function menu(state = false, { type }) {
  switch (type) {
    case OPEN_MENU:
      return true
    case CLOSE_MENU:
      return false
    case TOGGLE_MENU:
      return !state
    default:
      return state
  }
}

export const openMenu = () => ({ type: OPEN_MENU })
export const closeMenu = () => ({ type: CLOSE_MENU })
export const toggleMenu = () => ({ type: TOGGLE_MENU })
