import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"

const Overlay = ({ classes, ...props }) => (
  <div className={classes.overlay} {...props} />
)

const styles = ({ center, bottom } = {}) => theme => ({
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1000,
    background: theme.background,
    display: center || bottom ? "flex" : "inherit",
    justifyContent: center ? "center" : "flex-start",
    alignItems: center ? "center" : bottom ? "flex-end" : "flex-start",
  },
})

export const CenterOverlay = withStyles(styles({ center: true }))(Overlay)
export const BottomOverlay = withStyles(styles({ bottom: true }))(Overlay)

export default withStyles(styles())(Overlay)
