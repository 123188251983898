import { combineReducers } from "redux"
import feedback from "../../containers/Feedbacks/reducer.js"
import video from "../../containers/FeedbackVideo/reducer.js"
import menu from "./reducers/menu"

const reducers = combineReducers({
  feedback,
  video,
  menu,
})

export default reducers
