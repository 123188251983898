import React from "react"

export const whyDidYouRenderService = () => {
  if (process.env.NODE_ENV === "development") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render")
    whyDidYouRender(React, { trackAllPureComponents: true })
  }
}

export default whyDidYouRenderService
