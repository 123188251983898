import React from "react"
import Dialog from "@material-ui/core/Dialog"
import Typography from "@material-ui/core/Typography"
import Video from "../../../components/Video"
import { makeStyles } from "@material-ui/core/styles"

const commonMistakePath =
  "https://s3.amazonaws.com/lapp-capstone/userguide/commonmistake.mp4"
const commentVideoPath =
  "https://s3.amazonaws.com/lapp-capstone/userguide/comment.mp4"
const audioVideoPath =
  "https://s3.amazonaws.com/lapp-capstone/userguide/audio.mp4"
const drawingVideoPath =
  "https://s3.amazonaws.com/lapp-capstone/userguide/drawing.mp4"
const timeVideoPath =
  "https://s3.amazonaws.com/lapp-capstone/userguide/time.mp4"

const useStyles = makeStyles(() => ({
  dialogPaper: {
    padding: "30px",
  },
}))

const EvaluateDialog = ({ open, handleClose }) => {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <div>
        <Typography variant="h3" style={{ margin: "20px 0px" }}>
          {"Guia para crear retroalimentación"}
        </Typography>
        <Typography variant="h5" style={{ margin: "20px 0px" }}>
          {"Cómo agregar comentarios"}
        </Typography>
        <Video
          videoId={`comments_video`}
          src={commentVideoPath}
          type="video/mp4"
          width="100%"
          height="auto"
          controls={false}
          preload={"none"}
          muted={true}
        />
        <Typography variant="h5" style={{ margin: "20px 0px" }}>
          {"Cómo agregar nota de voz"}
        </Typography>
        <Video
          videoId={`common_mistakes_video`}
          src={audioVideoPath}
          type="video/mp4"
          width="100%"
          height="auto"
          controls={false}
          preload={"none"}
          muted={true}
        />
        <Typography variant="h5" style={{ margin: "20px 0px" }}>
          {"Cómo agregar error común"}
        </Typography>
        <Video
          videoId={`common_mistakes_video`}
          src={commonMistakePath}
          type="video/mp4"
          width="100%"
          height="auto"
          controls={false}
          preload={"none"}
          muted={true}
        />
        <Typography variant="h5" style={{ margin: "20px 0px" }}>
          {"Cómo agregar dibujo"}
        </Typography>
        <Video
          videoId={`common_mistakes_video`}
          src={drawingVideoPath}
          type="video/mp4"
          width="100%"
          height="auto"
          controls={false}
          preload={"none"}
          muted={true}
        />
        <Typography variant="h5" style={{ margin: "20px 0px" }}>
          {"Cómo definir tiempos"}
        </Typography>
        <Video
          videoId={`time_video`}
          src={timeVideoPath}
          type="video/mp4"
          width="100%"
          height="auto"
          controls={false}
          preload={"none"}
          muted={true}
        />
      </div>
    </Dialog>
  )
}

export default EvaluateDialog
