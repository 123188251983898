import React from "react"
import ApolloClient from "apollo-client"
import { HttpLink } from "apollo-link-http"
import { onError } from "apollo-link-error"
import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloLink } from "apollo-client-preset"
import { ApolloProvider } from "react-apollo"
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks"

import { AUTH_TOKEN } from "../constants"

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL || "http://localhost:3000"}/graphql`,
})

const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(AUTH_TOKEN)
  const authorizationHeader = token
  if (token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${authorizationHeader}`,
      },
    })
  }
  return forward(operation)
})

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message }) => {
      if (message.match(/(invalid signature|jwt)/i)) {
        localStorage.removeItem(AUTH_TOKEN)
      }
    })
})

const httpLinkWithAuthToken = ApolloLink.from(
  [middlewareAuthLink, errorLink, httpLink].filter(e => e),
)
const client = new ApolloClient({
  link: httpLinkWithAuthToken,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
})

const Provider = ({ children }) => (
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>{children}</ApolloHooksProvider>
  </ApolloProvider>
)
export default Provider
