import * as Sentry from "@sentry/browser"
import config from "../config"
import userFromToken from "../utils/userFromToken"

export const setSentryUser = ({ email, id } = {}) => {
  if (email && id) {
    Sentry.configureScope(scope => {
      scope.setUser({ email, id })
    })
  } else {
    Sentry.configureScope(scope => {
      scope.setUser(null)
    })
  }
}

export default function enableSentry() {
  if (config.enableCrashReporting) {
    if (process.env.REACT_APP_SENTRY_DSN) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
      })
      const user = userFromToken()
      setSentryUser(user)
    } else {
      console.error("Unable to connect to Sentry")
    }
  }
}
