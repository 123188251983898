import React, { useEffect, useRef, useState } from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import ReactPlayer from "react-player"
import { getAssetUrl } from "../../utils/url"

import { useTranslation } from "react-i18next"

const Video = ({
  onProgress = () => {},
  setDuration,
  handleLoading = () => {},
  playing,
  url,
  subtitles = [],
  videoId,
  time,
  classes,
}) => {
  const player = useRef()
  const [currentTime, setTime] = useState(0)
  const { i18n } = useTranslation()
  useEffect(() => {
    if (Math.abs(currentTime - time) > 0.3) {
      setTime(time)
      if (player.current) player.current.seekTo(time)
    }
  }, [currentTime, time])
  const handleProgress = second => {
    setTime(second)
    onProgress(second)
  }
  return (
    <ReactPlayer
      ref={ref => {
        player.current = ref
      }}
      playing={playing}
      progressInterval={100}
      url={url}
      onProgress={({ playedSeconds }) => handleProgress(playedSeconds)}
      onDuration={setDuration}
      onReady={() => handleLoading(false)}
      onBuffer={() => handleLoading(true)}
      width="100%"
      height="auto"
      className={classes.player}
      id={videoId}
      config={{
        file: {
          attributes: {
            crossOrigin: "true",
          },
          tracks: subtitles
            .filter(({ language: { code } }) => code === i18n.language)
            .map(({ url, language: { code: srcLang } }) => ({
              kind: "subtitles",
              src: getAssetUrl(url),
              srcLang,
              default: true,
            })),
        },
      }}
    />
  )
}

const styles = {
  player: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}

export default withStyles(styles)(Video)
