import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import ErrorIcon from "@material-ui/icons/Error"
import { withTranslation } from "react-i18next"

class ServerError extends Component {
  render() {
    const { t } = this.props
    return (
      <Typography variant="h3">
        <ErrorIcon
          style={{
            fontSize: "40px",
            margin: "0px 20px",
            verticalAlign: "middle",
          }}
        />
        {t("errors.server_error")}
      </Typography>
    )
  }
}

const styles = () => ({})

export default withTranslation("common")(withStyles(styles)(ServerError))
