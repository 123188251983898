import React from "react"
import { useQuery } from "react-apollo"
import gql from "graphql-tag"
import { Redirect, useLocation } from "react-router"
import { parse } from "querystring"
import Splash from "../splash"
import NotFound from "../Errors/NotFound"

const NEED_AUTH_ERROR_PATH = "/login"
const NEED_UNAUTH_ERROR_PATH = "/"
export const useAuthStatus = () => {
  const { data, loading, error } = useQuery(
    gql`
      query getUser {
        viewerInfo {
          id
          role {
            id
            name
          }
        }
      }
    `,
    { fetchPolicy: "cache-first" },
  )
  const currentId = data && data.viewerInfo && data.viewerInfo.id
  const isSignedIn = !loading && !!currentId
  const currentRole =
    !loading && data && !error && isSignedIn && data.viewerInfo.role
  return { isSignedIn, currentRole, loading }
}

export const verifyAuthHOC = ({
  needAuth = true,
  needUnauth = false,
  requiredRoles = [],
} = {}) => Component => {
  const VerifiedComponent = props => {
    const { isSignedIn, currentRole, loading } = useAuthStatus()
    const { pathname, search } = useLocation()

    if (loading) return <Splash />
    if (needAuth && !isSignedIn) {
      return <Redirect to={`${NEED_AUTH_ERROR_PATH}?redirect=${pathname}`} />
    }
    if (needUnauth && isSignedIn) {
      const { redirect } = parse(search.replace("?", ""))
      return <Redirect to={`${redirect || NEED_UNAUTH_ERROR_PATH}`} />
    }
    if (
      needAuth &&
      isSignedIn &&
      requiredRoles.length > 0 &&
      !requiredRoles.includes(currentRole.name)
    ) {
      return <NotFound /> // TODO: Not found
    }

    return <Component {...props} />
  }
  return VerifiedComponent
}
