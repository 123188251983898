/* Supported Feedback types */
const COMMENT = "comment"
const AUDIO = "audio"
const VIDEO = "video"
const DRAWING = "drawing"
const defaultState = {
  feedbacks: {
    //'0': {
    //type: COMMENT,
    //time: 0,
    //text: 'foo',
    //},
  },
  feedbackGlobes: {},
  lastId: 0,
}

const feedback = (state = defaultState, action) => {
  switch (action.type) {
    case "SET_FEEDBACK_INITIAL_CONDITIONS":
      return {
        ...state,
        feedbacks: {},
        showCommentBox: false,
        showDrawing: false,
        canPlay: false,
        showAudioRecording: false,
        showTimeSelector: false,
        lastId: 0,
        currentDrawing: [],
        timeRanges: [],
        approveStatus: false,
        osats: [],
      }
    case "CAN_PLAY":
      return {
        ...state,
        canPlay: true,
      }
    // Mark the local feedback as successfully uploaded to the server
    case "FEEDBACK_UPLOADED":
      return {
        ...state,
        feedbacks: {
          ...state.feedbacks,
          [action.payload.id]: {
            ...state.feedbacks[action.payload.id],
            uploaded: true,
            // We need to store the id in the server for deletion/edition
            serverId: action.payload.serverId,
          },
        },
        feedbackGlobes: {
          ...state.feedbackGlobes,
          [action.payload.time]: {
            id: action.payload.feedbackGlobeId,
          },
        },
      }
    case "SET_FEEDBACK_LESSON_TIME": {
      const index = state.timeRanges.findIndex(
        ({ exerciseSectionId }) =>
          exerciseSectionId === action.payload.exerciseSectionId,
      )
      if (index > -1) {
        return {
          ...state,
          timeRanges: [
            ...state.timeRanges.slice(0, index),
            {
              ...state.timeRanges[index],
              beginning: action.payload.beginning,
              ending: action.payload.ending,
              exerciseSectionId: action.payload.exerciseSectionId,
            },
            ...state.timeRanges.slice(index + 1),
          ],
        }
      } else {
        return {
          ...state,
          timeRanges: [
            ...state.timeRanges,
            {
              ...state.timeRanges[index],
              beginning: action.payload.beginning,
              ending: action.payload.ending,
              exerciseSectionId: action.payload.exerciseSectionId,
            },
          ],
        }
      }
    }
    case "TOGGLE_FEEDBACK_TIME_SELECTOR":
      if (!state.canPlay) {
        return { ...state, showTimeSelector: false }
      } else {
        return {
          ...state,
          showTimeSelector: !state.showTimeSelector,
        }
      }
    case "TOGGLE_FEEDBACK_COMMENT_BOX":
      return {
        ...state,
        showCommentBox: !state.showCommentBox,
        showAudioRecording: false,
        showDrawing: false,
      }
    case "SET_FEEDBACK_APPROVE_STATUS": {
      return {
        ...state,
        approveStatus: action.payload.status,
      }
    }
    case "TOGGLE_FEEDBACK_AUDIO_RECORDING":
      return {
        ...state,
        showAudioRecording: !state.showAudioRecording,
        showCommentBox: false,
        showDrawing: false,
      }
    case "SET_OSATS":
      return {
        ...state,
        osats: action.payload.osats,
      }
    case "TOGGLE_FEEDBACK_DRAWING":
      return {
        ...state,
        showDrawing: !state.showDrawing,
        showCommentBox: false,
        showAudioRecording: false,
      }
    case "SET_FEEDBACK_CURRENT_DRAWING":
      return {
        ...state,
        currentDrawing: [action.payload.image, action.payload.background],
      }
    case "ADD_FEEDBACK_COMMENT":
      return {
        ...state,
        lastId: state.lastId + 1,
        feedbacks: {
          ...state.feedbacks,
          [state.lastId + 1]: {
            type: COMMENT,
            time: action.payload.time,
            text: action.payload.comment,
            uploaded: !!action.payload.uploaded,
            serverId: action.payload.serverId,
          },
        },
      }
    case "ADD_FEEDBACK_GLOBE":
      return {
        ...state,
        feedbackGlobes: {
          ...state.feedbackGlobes,
          [action.payload.time]: {
            id: action.payload.id,
          },
        },
      }
    case "ADD_FEEDBACK_DRAWING":
      //console.log(state.currentDrawing)
      return {
        ...state,
        lastId: state.lastId + 1,
        feedbacks: {
          ...state.feedbacks,
          [state.lastId + 1]: {
            type: DRAWING,
            time: action.payload.time,
            drawing: [...state.currentDrawing],
            uploaded: !!action.payload.uploaded,
            serverId: action.payload.serverId,
          },
        },
      }
    case "ADD_FEEDBACK_COMMON_ERROR":
      return {
        ...state,
        lastId: state.lastId + 1,
        feedbacks: {
          ...state.feedbacks,
          [state.lastId + 1]: {
            type: VIDEO,
            time: action.payload.time,
            id: action.payload.id,
            mistake: action.payload.mistake,
            uploaded: !!action.payload.uploaded,
            serverId: action.payload.serverId,
          },
        },
      }
    case "ADD_FEEDBACK_AUDIO_LINK":
      return {
        ...state,
        lastId: state.lastId + 1,
        feedbacks: {
          ...state.feedbacks,
          [state.lastId + 1]: {
            link: action.payload.link,
            //blob: new Blob(, {type: "audio/webm;codecs=opus"}),
            type: AUDIO,
            time: action.payload.time,
            uploaded: true,
            serverId: action.payload.serverId,
          },
        },
      }
    case "ADD_FEEDBACK_AUDIO_RECORDING": {
      let lastId = state.lastId
      const audios = action.payload.audios.reduce((r, a) => {
        r[++lastId] = {
          ...a,
          type: AUDIO,
          time: action.payload.time,
          uploaded: !!action.payload.uploaded,
          serverId: action.payload.serverId,
        }
        return r
      }, {})
      return {
        ...state,
        lastId: lastId,
        feedbacks: {
          ...state.feedbacks,
          ...audios,
        },
      }
    }
    case "DELETE_FEEDBACK":
      return {
        ...state,
        // Delete the feedback with matching id
        feedbacks: Object.keys(state.feedbacks).reduce((f, key) => {
          if (key !== action.payload.id) {
            f[key] = state.feedbacks[key]
          }
          return f
        }, {}),
        // Delete feedback globes if its now empty
        feedbackGlobes: {
          ...state.feedbackGlobes,
          // If there is only one matching feedback for the time, then delete
          // the feedback globe because there will now be 0 items for that
          // feedback globe.
          [state.feedbacks[action.payload.id].time]:
            Object.values(state.feedbacks).filter(f => {
              return f.time === state.feedbacks[action.payload.id].time // find feedbacks whos time is equal to the one we deleted
            }).length === 1
              ? null
              : state.feedbackGlobes[state.feedbacks[action.payload.id].time],
        },
      }
    default:
      return state
  }
}

export default feedback
