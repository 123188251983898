import React from "react"

import Fab from "@material-ui/core/Fab"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import { CenterOverlay } from "../Overlay"
import withStyles from "@material-ui/core/styles/withStyles"

const PlayOverlay = ({ classes, playing, togglePlaying }) =>
  !playing && (
    <CenterOverlay style={{ background: "rgba(0,0,0,0.3)" }}>
      <Fab
        className={classes.playIcon}
        color="secondary"
        onClick={togglePlaying}
      >
        <PlayArrowIcon />
      </Fab>
    </CenterOverlay>
  )

const styles = {
  playIcon: {},
}

export default withStyles(styles)(PlayOverlay)
