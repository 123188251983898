import React, { useState } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import Video from "./Video"
import ProgressBar from "./ProgressBar"
import PlayOverlay from "../Overlay/PlayOverlay"
import LoadingOverlay from "../Overlay/LoadingOverlay"

const Player = ({ url, subtitles = [], videoId, markers = [], classes }) => {
  const [duration, setDuration] = useState(null)
  const [time, setTime] = useState(0)
  const [playing, setPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const togglePlaying = () => setPlaying(!playing)
  return (
    <div>
      <div onClick={togglePlaying} className={classes.container}>
        <LoadingOverlay loading={isLoading} />
        <PlayOverlay playing={playing} togglePlaying={togglePlaying} />
        <Video
          onProgress={setTime}
          setDuration={setDuration}
          handleLoading={setIsLoading}
          playing={playing}
          url={url}
          subtitles={subtitles}
          videoId={videoId}
          time={time}
        />
      </div>
      <ProgressBar
        seek={setTime}
        current={time}
        duration={duration}
        setThumbnailTime={() => {}}
        markers={markers}
      />
    </div>
  )
}

export default withStyles(() => ({
  container: {
    position: "relative",
    cursor: "pointer",
  },
}))(Player)
