import React, { Component } from "react"
import { withRouter } from "react-router-dom"

/* Material UI */
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Badge from "@material-ui/core/Badge"
import Drawer from "@material-ui/core/Drawer"

/* Icons */
import Favorite from "@material-ui/icons/Favorite"
import Assignment from "@material-ui/icons/Assignment"
import Dashboard from "@material-ui/icons/Dashboard"
import History from "@material-ui/icons/History"
import Home from "@material-ui/icons/Home"
import PersonAdd from "@material-ui/icons/PersonAdd"
import Place from "@material-ui/icons/Place"
import Class from "@material-ui/icons/Class"
import ErrorIcon from "@material-ui/icons/Error"
import Book from "@material-ui/icons/Book"
import Toc from "@material-ui/icons/Toc"
import LockOpen from "@material-ui/icons/LockOpen"
//import CloudUpload from '@material-ui/icons/CloudUpload';

import { withTranslation } from "react-i18next"

// COMPONENT
class SideBar extends Component {
  static defaultProps = {
    feedbacks: 0,
    completedFeedbacks: [],
  }
  changePage = path => {
    this.props.history.push(path)
  }

  render() {
    const { t, classes, feedbacks, completedFeedbacks, role } = this.props
    if (role === "") {
      return (
        <Drawer
          open={this.props.open}
          anchor="left"
          onClose={this.props.toggle}
          data-testid="sidebar"
        >
          <br />
          <div
            tabIndex={0}
            role="button"
            style={{ width: 300 }}
            onClick={this.props.toggle}
            onKeyDown={this.props.toggle}
          >
            Loading
          </div>
        </Drawer>
      )
    }

    const pathsForRole = {
      superadmin: [
        ["/", t("sidebar.home"), <Home key="component" />],
        ["/dashboard", t("sidebar.dashboard"), <Dashboard key="component" />],
        [
          "/evaluaciones",
          t("sidebar.feedbacks"),
          <Assignment key="component" />,
        ],
        [
          "/upload/feedback",
          t("sidebar.feedbacksCreate"),
          <Assignment key="component" />,
        ],
        ["/historial", t("sidebar.history"), <History key="component" />],
        ["/usuarios", t("sidebar.users"), <PersonAdd key="component" />],
        ["/centros", t("sidebar.establishments"), <Place key="component" />],
        ["/tutoriales", t("sidebar.tutorials"), <Class key="component" />],
        [
          "/errores-comunes",
          t("sidebar.commonMistakes"),
          <ErrorIcon key="component" />,
        ],
        [
          "/material-teorico",
          t("sidebar.theoricMaterial"),
          <Book key="component" />,
        ],
        ["/encuestas", t("sidebar.surveys"), <Book key="component" />],
        ["/examenes", t("sidebar.exams"), <Book key="component" />],
        ["/logros", t("awards.title"), <Book key="components" />],
        [
          "/niveles-de-usuario",
          t("userLevel.title"),
          <Book key="components" />,
        ],
        //['/subir',t('sidebar.upload'), <CloudUpload/>],
      ],
      admin: [
        ["/", t("sidebar.home"), <Home key="component" />],
        [
          "/evaluaciones",
          t("sidebar.feedbacks"),
          <Assignment key="component" />,
        ],
        [
          "/upload/feedback",
          t("sidebar.feedbacksCreate"),
          <Assignment key="component" />,
        ],
        ["/historial", t("sidebar.history"), <History key="component" />],
        ["/asistencias", t("sidebar.attendance"), <Toc key="component" />],
        ["/usuarios", t("sidebar.users"), <PersonAdd key="component" />],
        [
          "/material-teorico",
          t("sidebar.theoricMaterial"),
          <Book key="component" />,
        ],
        ["/redeem", "Canjear código de compra", <LockOpen key="lock" />],
        //['/tutoriales',t('sidebar.tutorials'), <Class/>],
        //['/errores-comunes',t('sidebar.commonMistakes'), <ErrorIcon/>],
        //['/subir',t('sidebar.upload'), <CloudUpload/>],
      ],
      teacher: [
        ["/", t("sidebar.home"), <Home key="component" />],
        [
          "/evaluaciones",
          t("sidebar.feedbacks"),
          <Assignment key="component" />,
        ],
        ["/historial", t("sidebar.history"), <History key="component" />],
        ["/tutoriales", t("sidebar.tutorials"), <Class key="component" />],
        [
          "/feedbacks-favoritos",
          "Feedbacks Favoritos",
          <Favorite key="component" />,
        ],
        [
          "/errores-comunes",
          t("sidebar.commonMistakes"),
          <ErrorIcon key="component" />,
        ],
        [
          "/material-teorico",
          t("sidebar.theoricMaterial"),
          <Book key="component" />,
        ],
      ],
      student: [],
    }

    // Get the dom element for a given path
    const listItem = (role, path, i) => {
      // Teacer has a badge with pending feedbacks
      let badge = null
      if (role === "teacher" && path[0] === "/evaluaciones" && feedbacks > 0) {
        badge = (
          <Badge
            badgeContent={feedbacks}
            color="secondary"
            className={classes.pending_badge}
          >
            <div />
          </Badge>
        )
      }
      if (
        role === "teacher" &&
        path[0] === "/historial" &&
        completedFeedbacks &&
        completedFeedbacks.length > 0
      ) {
        let canReplyFeedback = completedFeedbacks.filter(f => {
          if (f.studentFeedbackEvaluation === null) {
            return false
          }
          if (f.studentFeedbackEvaluation.seen) {
            return false
          }
          if (f.teacherReplyStudent.length > 0) {
            return false
          }
          return true
        })
        if (canReplyFeedback.length > 0) {
          badge = (
            <Badge
              badgeContent={canReplyFeedback.length}
              color="secondary"
              className={classes.pending_badge}
            >
              <div />
            </Badge>
          )
        }
      }

      // Current path should be visible
      const isCurrentLocationStyle = this.props.location.pathname === path[0]
      // Element of the sidebar
      return (
        <ListItem
          className={
            isCurrentLocationStyle
              ? classes.activeSidebar
              : classes.inactiveSidebar
          }
          key={i}
          button
          onClick={() => this.changePage(path[0])}
          data-tour={`sidebar-${path[0].slice(1)}`}
        >
          <div className={classes.icon}>{path[2]}</div>&nbsp;{path[1]}
          {badge}
        </ListItem>
      )
    }

    // Get all the sidebar dom elements for a given role
    const sidebarElements = role => {
      const paths = pathsForRole[role] || []
      return (
        <div>
          {paths.map((path, i) => {
            return listItem(role, path, i)
          })}
        </div>
      )
    }

    const small = true //this.props.width === 'xs' || this.props.width === 'sm';
    return small ? (
      <Drawer
        open={this.props.open}
        anchor="left"
        onClose={this.props.toggle}
        data-testid="sidebar"
      >
        <br />
        <div
          tabIndex={0}
          role="button"
          style={{ width: 300 }}
          onClick={this.props.toggle}
          onKeyDown={this.props.toggle}
        >
          {sidebarElements(role)}
        </div>
      </Drawer>
    ) : (
      <Grid item md={3}>
        <List>{sidebarElements(role)}</List>
      </Grid>
    )
  }
}

const styles = theme => ({
  pending_badge: {
    bottom: 12,
    left: 12,
  },
  activeSidebar: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    borderRadius: "2px",
  },
  inactiveSidebar: {
    color: "rgb(114,114,114)",
  },
  icon: {
    display: "inline-block",
    fontSize: "10px !important",
    margin: theme.spacing(1),
  },
})

export default withTranslation("common")(
  withRouter(withStyles(styles)(SideBar)),
)
