import LogRocket from "logrocket"
import config from "../config"
import userFromToken from "../utils/userFromToken"

export const setLogrocketUser = user => {
  if (user) {
    LogRocket.identify(user.id, user)
  }
}

export default function enableLogrocket() {
  if (config.enableScreenRecording) {
    if (process.env.REACT_APP_LOGROCKET_APP) {
      LogRocket.init(process.env.REACT_APP_LOGROCKET_APP, {
        network: {
          isEnabled: true,
          responseSanitizer: response => response,
          requestSanitizer: request => request,
        },
        console: {
          isEnabled: true,
        },
      })
      const user = userFromToken()
      setLogrocketUser(user)
    }
  }
}
