export const togglePlayPause = () => ({
  type: "TOGGLE_PLAY_PAUSE",
})
export const play = () => ({
  type: "PLAY",
})
export const pause = () => ({
  type: "PAUSE",
})
export const canPlay = () => ({
  type: "CAN_PLAY",
})
export const setVideoPlayer = videoPlayer => ({
  type: "SET_VIDEO_PLAYER",
  payload: {
    videoPlayer: videoPlayer,
  },
})

export const restartVideo = () => ({
  type: "RESTART_VIDEO",
})

export const forwardVideo = () => ({
  type: "FORWARD_VIDEO",
})

export const rewindVideo = () => ({
  type: "REWIND_VIDEO",
})

export const tick = () => ({
  type: "TICK",
})
export const destroyVideo = () => ({
  type: "DESTROY_VIDEO",
})

export const setVideoTime = time => ({
  type: "SET_VIDEO_TIME",
  payload: {
    time: time,
  },
})

export const handleFullscreen = () => ({
  type: "SET_FULLSCREEN",
})
