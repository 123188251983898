const defaultState = {
  playing: false,
  videoPlayer: null,
  showInitialPlayIcon: true,
}
const video = (state = defaultState, action) => {
  switch (action.type) {
    case "SET_FEEDBACK_INITIAL_CONDITIONS":
      return {
        ...state,
        playing: false,
        videoPlayer: null,
        showInitialPlayIcon: true,
        canPlay: false,
        currentTime: 0,
      }
    case "SET_FULLSCREEN":
      return {
        ...state,
        showInitialPlayIcon: false,
      }
    case "TOGGLE_PLAY_PAUSE":
      return {
        ...state,
        playing: !state.playing,
        showInitialPlayIcon: false,
      }
    case "PLAY":
      return {
        ...state,
        playing: true,
      }
    case "PAUSE":
      return {
        ...state,
        playing: false,
      }
    case "CAN_PLAY":
      return {
        ...state,
        canPlay: true,
      }
    case "SET_VIDEO_PLAYER":
      return {
        ...state,
        videoPlayer: action.payload.videoPlayer,
      }
    case "TICK":
      return {
        ...state,
        currentTime: state.videoPlayer ? state.videoPlayer.getCurrentTime() : 0,
      }
    case "RESTART_VIDEO":
      return {
        ...state,
      }
    case "TOGGLE_FEEDBACK_DRAWING":
      return {
        ...state,
        showInitialPlayIcon: false,
      }
    case "SET_THUMBNAIL":
      return {
        ...state,
        thumbnailUrl: action.payload.thumbnailUrl,
      }
    case "SET_THUMBNAIL_MARGIN_LEFT":
      return {
        ...state,
        thumbnailMarginLeft: action.payload.thumbnailMarginLeft,
      }
    case "UNSET_THUMBNAIL":
      return {
        ...state,
        thumbnailUrl: null,
      }
    default:
      return state
  }
}

export default video
