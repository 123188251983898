import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { useTranslation } from "react-i18next"

const useStyle = makeStyles({
  container: {},
})
const NotFound = () => {
  const { t } = useTranslation("common")
  const classes = useStyle()

  return (
    <div className={classes.container}>
      <Typography variant="h6">{t("errors.notFound")}</Typography>
    </div>
  )
}

export default NotFound
