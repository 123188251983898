export const getAssetUrl = (
  path,
  prefix = process.env.REACT_APP_ASSETS_PREFIX,
) => {
  if (path.search("http") === 0) {
    console.error(`Path "${path}" is a full URL`)
    return path
  }
  return `${prefix}${path}`
}
