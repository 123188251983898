import config from "../config"
import { setSentryUser } from "./sentry"
import { setLogrocketUser } from "./logrocket"

export const setUser = user => {
  if (config.enableCrashReporting) {
    setSentryUser(user)
  }
  if (config.enableScreenRecording) {
    setLogrocketUser(user)
  }
}

export default setUser
