/* React */
import React from "react"
import ReactDOM from "react-dom"

/* React - Router */
import { BrowserRouter } from "react-router-dom"

/* Providers */
import ApolloProvider from "./config/apollo"
import MuiThemeProvider from "./config/mui"
import ReduxProvider from "./config/redux"
import i18nInit from "./config/i18n"

/* Utilities */
// import registerServiceWorker from "./registerServiceWorker"
import "typeface-roboto"
import "./index.css"
import "react-select/dist/react-select.css"

/* Components */
import App from "./containers/App"
import NavBar from "./screens/NavBar"

import enableSentry from "./services/sentry"
import enableLogrocket from "./services/logrocket"
import FCM from "./services/firebase/push-notification"
import whyDidYouRenderService from "./services/whyDidYouRender"
import packagejson from "../package.json"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

const services = [
  enableSentry,
  enableLogrocket,
  FCM,
  whyDidYouRenderService,
  i18nInit,
]
services.forEach(service => service())

// eslint-disable-next-line no-console
console.log(`App versión ${packagejson.version}`)

ReactDOM.render(
  <ApolloProvider>
    <ReduxProvider>
      <MuiThemeProvider>
        <BrowserRouter>
          <NavBar />
          <App />
          <ToastContainer />
        </BrowserRouter>
      </MuiThemeProvider>
    </ReduxProvider>
  </ApolloProvider>,
  document.getElementById("root"),
)
// registerServiceWorker()
