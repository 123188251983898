import React from "react"
import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import videoMiddleware from "../../containers/FeedbackVideo/middleware.js"
import reducer from "./reducers"

/* Redux */
const middleware = applyMiddleware(videoMiddleware)
const store = createStore(reducer, {}, middleware)

const ReduxProvider = ({ children }) => (
  <Provider store={store}>{children}</Provider>
)
export default ReduxProvider
