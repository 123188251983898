import React, { Component } from "react"
import { graphql } from "react-apollo"
import { compose } from "recompose"
import Sidebar from "./components/Sidebar"
import ServerError from "../../components/Errors/ServerError"
import gql from "graphql-tag"

// CONTAINER
class SideBarContainer extends Component {
  changePage = path => {
    this.props.history.push(path)
  }

  render() {
    const {
      data: {
        loading,
        error,
        pendingFeedbacks,
        completedFeedbacks,
        viewerInfo: { role: { name = "" } = {} } = {},
      } = {},
    } = this.props

    if (error) {
      //console.log(this.props.getFeedbacks)
      return <ServerError />
    }

    const feedbacks = pendingFeedbacks
    const role = !loading ? name : ""

    return (
      <Sidebar
        feedbacks={feedbacks}
        completedFeedbacks={completedFeedbacks}
        role={role}
        open={this.props.open}
        toggle={this.props.toggle}
      />
    )
  }
}

export default compose(
  graphql(gql`
    query {
      viewerInfo {
        id
        role {
          id
          name
        }
      }
      pendingFeedbacks: summaryFeedbacks(where: { done: false }) {
        count
      }
      completedFeedbacks: feedbacks(where: { done: true }) {
        id
        studentFeedbackEvaluation {
          id
          seen
        }
        teacherReplyStudent {
          id
        }
      }
    }
  `),
)(SideBarContainer)
