import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import { useLocation, useHistory } from "react-router-dom"
import Toolbar from "@material-ui/core/Toolbar"
import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import InfoIcon from "@material-ui/icons/Info"
import SideBar from "../../containers/SideBar"
import lappLogo from "../../assets/images/lapp_logo_white.png"
import ControlledOpenSelect from "./components/ControlledOpenSelect"
import { useLogout } from "./components/logoutHook"
import EvaluateHelpDialog from "./components/EvaluateDialog"

import { useTranslation } from "react-i18next"
import { toggleMenu as toggleMenuAction } from "../../config/redux/reducers/menu"

import { useAuthStatus } from "../../components/security/auth"

const useStyle = makeStyles({
  logo: {
    width: "170px",
    borderRadius: "10px",
  },
  mainContainer: {
    padding: "10px 0",
  },
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  appBar: {},
})

const NavBar = () => {
  const [helpDialog, setOpenHelpDialog] = useState(false)
  const handleLogout = useLogout()
  const activateTour = () => setOpenHelpDialog(true)
  const closeTour = () => setOpenHelpDialog(false)
  const { currentRole: { name: role } = {}, isSignedIn } = useAuthStatus()
  const classes = useStyle()
  const { pathname } = useLocation()
  const { push } = useHistory()
  const { openMenu } = useSelector(({ menu }) => ({ openMenu: menu }))
  const dispatch = useDispatch()
  const toggleMenu = () => dispatch(toggleMenuAction())
  const { t } = useTranslation("common")
  const logout = async () => {
    await handleLogout()
  }
  if (!isSignedIn) return null
  return (
    <div>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          {role !== "student" && (
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={toggleMenu}
              data-testid="navbar-button"
              data-tour="menu-icon"
            >
              <MenuIcon />
            </IconButton>
          )}

          <IconButton
            color="inherit"
            aria-label="Menu"
            href="/"
            className={classes.logo}
          >
            <img height="40" width="150" src={lappLogo} alt="Lapp Logo" />
          </IconButton>
          <div className={classes.flex} />

          {pathname.split("/")[1] === "evaluar" && (
            <IconButton
              data-toggle="chardinjs"
              onClick={activateTour}
              id="chardinTrigger"
            >
              <InfoIcon />
            </IconButton>
          )}

          <ControlledOpenSelect />

          <Button
            color="inherit"
            data-tour="tutorial-profile"
            onClick={() => push("/perfil")}
          >
            {t("nav.profile")}
          </Button>
          <Button color="inherit" onClick={logout}>
            {t("login.logout")}
          </Button>
        </Toolbar>
      </AppBar>

      <EvaluateHelpDialog open={helpDialog} handleClose={closeTour} />

      <SideBar open={openMenu} toggle={toggleMenu} />
    </div>
  )
}

export default NavBar
